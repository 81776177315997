import { Routes, Route, useNavigate } from "react-router-dom";
// Material-UI imports

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import Home from "./pages/home";
import Upload from "./pages/upload";
import ArticlePage from "./pages/article";

// Sample app imports
type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  // const history = useNavigate();
  // const navigationClient = new CustomNavigationClient(history);
  // pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
          <Pages />
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      {/* <Route path="/upload" element={<Upload />}></Route> */}
      <Route path="/article/:ref" element={<ArticlePage />}></Route>
    </Routes>
  );
}

export default App;
