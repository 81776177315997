import { Navbar } from "flowbite-react";

export const Header = () => {
    return (
        <div className="mb-2">
            <Navbar
                fluid
                rounded
                // className="border-b-2 border-gray-200 dark:border-gray-700"
            >
                <Navbar.Brand className="h-10 md:container mx-auto" href="/">
                    <img
                        alt="Flowbite React Logo"
                        className="mr-3 h-20"
                        src="/logo.svg"
                    />
                    <span className="font-gotham italic font-bold self-center whitespace-nowrap text-xl dark:text-white">
                        SKATING MAGAZINE ARCHIVE
                    </span>
                </Navbar.Brand>

            </Navbar>
        </div>
    );
};
