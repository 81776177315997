import axios from "axios";
import {
  DetailedHTMLProps,
  FC,
  ImgHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { Article } from "../models/article";
import ReactMarkdown from "react-markdown";
import Image from "../components/image";
import { Header } from "../components/header";

interface CustomImageRendererProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string;
}

const CustomImageRenderer: React.FC<CustomImageRendererProps> = ({
  src,
  alt,
}) => {
  return <Image className="w-full" src={src.replace(/\.tif$/, '.jpg')} />;
};

const ArticlePage: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [article, setArticle] = useState<Article | null>(null);
  let { ref } = useParams();
  useEffect(() => {
    const fetchLatestArticles = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`api/article/${ref}`);
        setArticle(response.data);
      } catch (error) {
        console.error(error);
      }
        setLoading(false);
    };

    fetchLatestArticles();
  }, [ref]);

  return (
    <div>
      <Header />
      {loading && (
        <div role="status" className="animate-pulse">
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[640px] mb-2.5 mx-auto"></div>
          <div className="h-2.5 mx-auto bg-gray-300 rounded-full dark:bg-gray-700 max-w-[540px] mb-2.5"></div>

          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[640px] mb-2.5 mx-auto"></div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5 mx-auto"></div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[720px] mb-2.5 mx-auto"></div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[800px] mb-2.5 mx-auto"></div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[560px] mb-2.5 mx-auto"></div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[960px] mb-2.5 mx-auto"></div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5 mx-auto"></div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[680px] mb-2.5 mx-auto"></div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[520px] mb-2.5 mx-auto"></div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[880px] mb-2.5 mx-auto"></div>
          <div className="flex items-center justify-center mt-4">
            <svg
              className="w-10 h-10 mr-2 text-gray-200 dark:text-gray-700"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <div className="w-20 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mr-3"></div>
            <div className="w-24 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>

          <span className="sr-only">Loading...</span>
        </div>
      )}
      {!loading && article?.body && (
        <div className="mt-2">
          <article className="container mx-auto prose lg:prose-xl">
            <ReactMarkdown components={{ img: CustomImageRenderer as any }}>
              {article.body}
            </ReactMarkdown>
          </article>
        </div>
      )}
    </div>
  );
};

export default ArticlePage;
