import { Button } from "@mui/material";
import React from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["GothamNarrow", "sans-serif"].join(","),
  },
});

const Hero: React.FC = () => {
  return (
    <div className="flex flex-col sm:flex-row relative overflow-hidden bg-gradient-to-r from-[#4863AE] to-[#232769] text-white">
      <div className="relative z-10 px-5 py-5 sm:w-1/2 w-full">
        <div className="sm:text-center md:text-left">
          <h1 className="text-3xl tracking-tight sm:text-5xl md:text-3xl">
            <span className="block font-gotham italic font-bold ">
              WELCOME TO
            </span>
            <span className="block font-gotham italic font-bold ">
              SKATING MAGAZINE
            </span>
            <span className="block font-gotham italic font-bold ">ARCHIVE</span>
          </h1>
          <p className="mt-3 font-gotham font-bold text-base  sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-sm lg:mx-0">
            THE OFFICAL PUBLICATION TO U.S. FIGURE SKATING
          </p>
          <div className="text-xs mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <ThemeProvider theme={theme}>
              <Button
                color="neutral"
                variant="outlined"
                size="small"
                sx={{ mx: 1, borderRadius: 5, width: 200 }}
              >
                <p>HOW TO SEARCH</p>
              </Button>
              <Button
                color="neutral"
                variant="outlined"
                size="small"
                sx={{ mx: 1, borderRadius: 5, width: 200 }}
              >
                <p>SUBSCRIBE</p>
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </div>
      <div className="relative w-1/2 hidden sm:block">
        <div
          style={{ clipPath: "polygon(10% 0, 0 100%, 100% 100%, 100% 0)" }}
          className="absolute inset-0 h-full w-full object-cover z-10 bg-slate-400"
        >
          {/* <div className="flex flex-col justify-center items-center h-full ml-16 p-5 text-center text-sm font-bold">
            <p>
              The Skating Magazine Archive is currently completing a major
              upgrade. During the month of July we will be completing additional
              testing and backfilling additional articles.
            </p>
            <p>
              If you cannot find the article you are looking for, please check
              back at a later date
            </p>
          </div> */}
        </div>
      </div>
      <div className="relative h-[200px] sm:hidden">
        <div
          style={{ clipPath: "polygon(0 0, 0 100%, 100% 100%, 100% 10%)" }}
          className="absolute inset-0 h-full w-full object-cover z-10 bg-slate-400"
        >
          {/* <div className="flex flex-col justify-center items-center h-full p-5 text-center text-sm font-bold">
            <p>
              The Skating Magazine Archive is currently completing a major
              upgrade. During the month of July we will be completing additional
              testing and backfilling additional articles.
            </p>
            <p>
              If you cannot find the article you are looking for, please check
              back at a later date
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

// const Hero: React.FC = () => {
//     return (
//         <div className="relative bg-white overflow-hidden">
//             <div className="mx-auto">
//                 <div className="relative z-10 px-3 py-7 bg-gradient-to-r from-indigo-500 to-indigo-900 md:max-w-1/4 after:translate-y-50 after:rotate-45">
//                     <svg
//                         className="hidden md:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
//                         fill="currentColor"
//                         viewBox="0 0 100 100"
//                         preserveAspectRatio="none"
//                         aria-hidden="true"
//                     >
//                         <polygon points="50,0 100,0 50,100 0,100" />
//                     </svg>

//                     <main className="mx-auto max-w-7xl px-4  sm:px-6 lg:px-8 text-white">
//                         <div className="sm:text-center lg:text-left">
//                             <h1 className="text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl">
//                                 <span className="block xl:inline">
//                                     WELCOME TO STAKING MAGAZINE ARCHIVE
//                                 </span>

//                             </h1>
//                             <p className="mt-3 text-base  sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-md lg:mx-0">
//                                 THE OFFICAL PUBLICATION FOR U.S. FIGURE SKATING
//                             </p>
//                             <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
//                                 <Button
//                                     color="transparent"
//                                     pill
//                                     className='border border-white hover:bg-white hover:text-indigo-500 mx-1'
//                                 >
//                                     <p>
//                                         HOW TO SEARCH
//                                     </p>
//                                 </Button>
//                                 <Button
//                                     color="transparent"
//                                     pill
//                                     className='border border-white hover:bg-white hover:text-indigo-500 mx-1'
//                                 >
//                                     <p>
//                                         SUBSCRIBE
//                                     </p>
//                                 </Button>
//                             </div>
//                         </div>
//                     </main>
//                 </div>
//             </div>
//             <div className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
//                 <img
//                     className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
//                     src="https://images.unsplash.com/photo-1491466424936-e304919aada7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8OXx8bm9ydGhlcm4lMjBsaWdodHN8ZW58MHwwfDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
//                     alt=""
//                 />
//             </div>
//         </div>
//     );
// }

export default Hero;
