import { useEffect, useState } from "react";
import { Header } from "../components/header";
import Layout from "../components/layout";
import { Article } from "../models/article";
import Image from "../components/image";
import Footer from "../components/footer";
import { Link, useLocation } from "react-router-dom";
import Hero from "../components/hero";
import SearchBar from "../components/search-bar";
import Pagination from "../components/pagination";
import ArticleCard from "../components/article-card";
import { Box } from "@mui/material";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const Home: React.FC = () => {
  const [count, setCount] = useState(0);
  const [latestArticles, setLatestArticles] = useState<Article[]>([]);
  const [totalItems, setTotalItems] = useState<number | null>(null);
  const [coverImg, setCoverImg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  let query = new URLSearchParams(location.search);

  let page = Number(query.get("page")) || 1;
  let pageSize = Number(query.get("pageSize")) || 5;
  let year = Number(query.get("year")) || null;
  let month = Number(query.get("month")) || null;
  let author = query.get("author") || null;
  let q = query.get("q") || null;

  useEffect(() => {
    const fetchLatestArticles = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`api/article/latest-articles`, {
          params: {
            page: page,
            pageSize: pageSize,
            q: q,
            year: year,
            month: month,
            author: author,
          },
        });
        setTotalItems(response.data.item1);
        setLatestArticles(response.data.item2);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchLatestArticles();
  }, [page, pageSize]);

  return (
    <div>
      <Header />
      <div className="container mx-auto">
        <Hero />
        <div className="px-5">
          <SearchBar q={q} year={year} month={month} author={author} />
          <Pagination page={page} pageSize={pageSize} totalItems={totalItems} q={q} year={year} month={month} author={author}/>
          {loading ? (
            <div className="flex justify-center items-center h-full m-5">
              <CircularProgress />
            </div>
          ) : (
            latestArticles.map((article, index) => (
              <Box key={article.identifier} sx={{ my: index === 0 ? 0 : 3 }}>
                  <ArticleCard article={article} searchValue={q}/>
              </Box>
            ))
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
