import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  Button,
  Chip,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { ChangeEvent, FormEvent, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ArticleCount from "../models/article-count";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface SearchBarProps {
  q: string | null;
  year: number | null;
  month: number | null;
  author: string | null;
}

const SearchBar: React.FC<SearchBarProps> = ({ q, author, month, year }) => {
  const [searchText, setSearchText] = React.useState<string>("");
  const [selectedAuthor, setSelectedAuthor] = React.useState<ArticleCount>({
    key: "Show all",
    count: -1,
  });
  const [selectedYear, setSelectedYear] = React.useState("Show all");
  const [selectedMonth, setSelectedMonth] = React.useState("Show all");
  const [authors, setAuthors] = React.useState<ArticleCount[]>([
    { key: "Show all", count: 0 },
  ]);
  const [years, setYears] = React.useState<ArticleCount[]>([
    { key: "Show all", count: 0 },
  ]);
  const [months, setMonths] = React.useState<ArticleCount[]>([
    { key: "Show all", count: 0 },
  ]);

  const monthMap: { [key: string]: string } = {
    "1": "01-January",
    "2": "02-February",
    "3": "03-March",
    "4": "04-April",
    "5": "05-May",
    "6": "06-June",
    "7": "07-July",
    "8": "08-August",
    "9": "09-September",
    "10": "10-October",
    "11": "11-November",
    "12": "12-December",
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (author) params.set("author", author);
    if (month) params.set("month", month.toString());
    if (year) params.set("year", year.toString());
    if (q) params.set("q", q.toString());

    const getCountByYearUrl = `/api/article/counts/year?${params.toString()}`;
    const getCountByMonthUrl = `/api/article/counts/month?${params.toString()}`;
    const getCountByAuthorUrl = `/api/article/counts/author?${params.toString()}`;
    const fetchData = async (url: string) => {
      try {
        const response = await axios.get<ArticleCount[]>(url);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
    fetchData(getCountByYearUrl).then((data) => {
      if (data) {
        const sortedData = data.sort((a, b) => Number(b.key) - Number(a.key));
        setYears([...years, ...sortedData]);
      }
    });
    fetchData(getCountByMonthUrl).then((data) => {
      if (data) {
        // sort data by key ascending

        const sortedData = data.sort((a, b) => Number(a.key) - Number(b.key));
        setMonths([...months, ...sortedData]);
      }
    });
    fetchData(getCountByAuthorUrl).then((data) => {
      if (data) {
        const sortedData = data.sort((a, b) => a.key.localeCompare(b.key));
        setAuthors([...authors, ...sortedData]);
        setSelectedAuthor(
          sortedData.find((a) => a.key === author) || {
            key: "Show all",
            count: 0,
          }
        );
      }
    });
    setSelectedYear(year === null ? "Show all" : year.toString());
    setSelectedMonth(month === null ? "Show all" : month.toString());
    setSelectedAuthor({ key: author || "Show all", count: -1 });
    setSearchText(q || "");
  }, []);

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleSearchClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (searchText === "") {
      urlParams.delete("q");
    } else {
      urlParams.set("q", searchText);
    }
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    window.location.reload();
  };

  const handleAuthorChange = (
    event: any,
    value: ArticleCount | null,
    reason: AutocompleteChangeReason
  ) => {
    const urlParams = new URLSearchParams(window.location.search);
    const author = value?.key;
    if (reason === "clear" || author === "Show all") {
      urlParams.delete("author");
    } else if (reason === "selectOption" && author) {
      urlParams.set("author", author);
    }

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    window.location.reload();
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    const selectedYear = event.target.value as string;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("year", selectedYear);
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    window.location.reload();
  };

  const handleMonthChange = (event: SelectChangeEvent) => {
    const selectedMonth = event.target.value as string;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("month", selectedMonth);
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    window.location.reload();
  };

  const submitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };


  return (
    <Box sx={{ mt: 1 }}>
      <Paper
        variant="outlined"
        style={{ backgroundColor: "#EBEBEB" }}
        className="px-3 pt-1 pb-3 bg-gray-500"
      >
        <form onSubmit={submitSearch}>
          <div className="hidden sm:block ">
          <div className="flex flex-col sm:flex-row items-center justify-between">
        <div className="sm:w-3/6 w-full">
          <label
            htmlFor="search"
            className="block text-sm font-normal text-gray-900 dark:text-white"
          >
            Search
          </label>
          <TextField
            id="search"
            className="bg-white  text-sm rounded-lg  block w-full p-2.5"
            value={searchText}
            size="small"
            fullWidth
            onChange={handleSearchTextChange}
          />
        </div>
        <div className="sm:w-1/6 mt-5 mx-2 w-full">
          <label
            htmlFor="author"
            className="block text-sm font-normal text-gray-900 dark:text-white"
          ></label>
          {/* <button className="w-full  bg-gradient-to-r from-indigo-500 to-indigo-900 hover:indigo-900 text-white font-bold py-2 px-4 rounded" > Search </button> */}
          <Button
            className="w-full bg-gradient-to-r from-[#4863AE] to-[#232769]"
            variant="contained"
            type="submit"
            endIcon={<SearchIcon />}
            onClick={handleSearchClick}
          >
            Search
          </Button>
        </div>
        <div className="sm:w-2/6 mx-2 w-full">
          <label
            htmlFor="author"
            className="block text-sm font-normal text-gray-900 dark:text-white"
          >
            Author
          </label>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={authors}
            getOptionLabel={(option) => {
              if (option.key === "Show all" || option.count < 0) {
                return option.key;
              } else {
                return option.key + " (" + option.count + ")";
              }
            }}
            // defaultValue={selectedAuthor}
            value={selectedAuthor}
            onChange={handleAuthorChange}
            renderInput={(params) => (
              <TextField
                {...params}
                color="primary"
                sx={{ backgroundColor: "white" }}
                size="small"
              />
            )}
            renderOption={(props, option) => (
              <ListItem
                key={option.key}
                {...props}
                sx={{ p: 0 }}
                secondaryAction={
                  option.key !== "Show all" && (
                    <Chip label={option.count} variant="outlined" />
                  )
                }
              >
                <ListItemText primary={option.key} sx={{ mr: 5 }} />
              </ListItem>
            )}
          />
        </div>
        <div className="sm:w-2/6  mx-2 w-full">
          <label
            htmlFor="year"
            className="block text-sm font-normal text-gray-900 dark:text-white"
          >
            Year
          </label>
          <Select
            sx={{
              fontWeight: "bold",
              "& .MuiSelect-select": {
                padding: "4px 10px",
              },
            }}
            className="w-full"
            size="small"
            labelId="year"
            id="year"
            value={selectedYear}
            label="Year"
            style={{ backgroundColor: "#fff" }}
            onChange={handleYearChange}
          >
            {years.map((y) => (
              <MenuItem key={y.key} value={y.key}>
                <ListItem
                  sx={{ p: 0 }}
                  secondaryAction={
                    y.key !== "Show all" && (
                      <Chip label={y.count} variant="outlined" />
                    )
                  }
                >
                  <ListItemText primary={y.key} />
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="sm:w-2/6  mx-2 w-full">
          <label
            htmlFor="month"
            className="block text-sm font-normal text-gray-900 dark:text-white"
          >
            Month
          </label>
          <Select
            sx={{
              fontWeight: "bold",
              "& .MuiSelect-select": {
                padding: "4px 10px",
              },
            }}
            className="w-full"
            size="small"
            labelId="month"
            id="month"
            value={selectedMonth}
            label="Month"
            style={{ backgroundColor: "#fff" }}
            onChange={handleMonthChange}
          >
            {months.map((m) => (
              <MenuItem key={m.key} value={m.key}>
                <ListItem
                  sx={{ p: 0 }}
                  secondaryAction={
                    m.key !== "Show all" && (
                      <Chip label={m.count} variant="outlined" />
                    )
                  }
                >
                  {m.key !== "Show all" ? (
                    <ListItemText primary={monthMap[m.key]} />
                  ) : (
                    <ListItemText primary={m.key} />
                  )}
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
          </div>
          <div className="sm:hidden">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="font-bold">Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div className="flex flex-col sm:flex-row items-center justify-between">
        <div className="sm:w-3/6 w-full">
          <label
            htmlFor="search"
            className="block text-sm font-normal text-gray-900 dark:text-white"
          >
            Search
          </label>
          <TextField
            id="search"
            className="bg-white  text-sm rounded-lg  block w-full p-2.5"
            value={searchText}
            size="small"
            fullWidth
            onChange={handleSearchTextChange}
          />
        </div>
        <div className="sm:w-1/6 mt-5 mx-2 w-full">
          <label
            htmlFor="author"
            className="block text-sm font-normal text-gray-900 dark:text-white"
          ></label>
          {/* <button className="w-full  bg-gradient-to-r from-indigo-500 to-indigo-900 hover:indigo-900 text-white font-bold py-2 px-4 rounded" > Search </button> */}
          <Button
            className="w-full bg-gradient-to-r from-[#4863AE] to-[#232769]"
            variant="contained"
            type="submit"
            endIcon={<SearchIcon />}
            onClick={handleSearchClick}
          >
            Search
          </Button>
        </div>
        <div className="sm:w-2/6 mx-2 w-full">
          <label
            htmlFor="author"
            className="block text-sm font-normal text-gray-900 dark:text-white"
          >
            Author
          </label>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={authors}
            getOptionLabel={(option) => {
              if (option.key === "Show all" || option.count < 0) {
                return option.key;
              } else {
                return option.key + " (" + option.count + ")";
              }
            }}
            // defaultValue={selectedAuthor}
            value={selectedAuthor}
            onChange={handleAuthorChange}
            renderInput={(params) => (
              <TextField
                {...params}
                color="primary"
                sx={{ backgroundColor: "white" }}
                size="small"
              />
            )}
            renderOption={(props, option) => (
              <ListItem
                key={option.key}
                {...props}
                sx={{ p: 0 }}
                secondaryAction={
                  option.key !== "Show all" && (
                    <Chip label={option.count} variant="outlined" />
                  )
                }
              >
                <ListItemText primary={option.key} sx={{ mr: 5 }} />
              </ListItem>
            )}
          />
        </div>
        <div className="sm:w-2/6  mx-2 w-full">
          <label
            htmlFor="year"
            className="block text-sm font-normal text-gray-900 dark:text-white"
          >
            Year
          </label>
          <Select
            sx={{
              fontWeight: "bold",
              "& .MuiSelect-select": {
                padding: "4px 10px",
              },
            }}
            className="w-full"
            size="small"
            labelId="year"
            id="year"
            value={selectedYear}
            label="Year"
            style={{ backgroundColor: "#fff" }}
            onChange={handleYearChange}
          >
            {years.map((y) => (
              <MenuItem key={y.key} value={y.key}>
                <ListItem
                  sx={{ p: 0 }}
                  secondaryAction={
                    y.key !== "Show all" && (
                      <Chip label={y.count} variant="outlined" />
                    )
                  }
                >
                  <ListItemText primary={y.key} />
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="sm:w-2/6  mx-2 w-full">
          <label
            htmlFor="month"
            className="block text-sm font-normal text-gray-900 dark:text-white"
          >
            Month
          </label>
          <Select
            sx={{
              fontWeight: "bold",
              "& .MuiSelect-select": {
                padding: "4px 10px",
              },
            }}
            className="w-full"
            size="small"
            labelId="month"
            id="month"
            value={selectedMonth}
            label="Month"
            style={{ backgroundColor: "#fff" }}
            onChange={handleMonthChange}
          >
            {months.map((m) => (
              <MenuItem key={m.key} value={m.key}>
                <ListItem
                  sx={{ p: 0 }}
                  secondaryAction={
                    m.key !== "Show all" && (
                      <Chip label={m.count} variant="outlined" />
                    )
                  }
                >
                  {m.key !== "Show all" ? (
                    <ListItemText primary={monthMap[m.key]} />
                  ) : (
                    <ListItemText primary={m.key} />
                  )}
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </form>
      </Paper>
    </Box>
  );
};

export default SearchBar;
