import {
  Box,
  Button,
  Card,
  Divider,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import Image from "../components/image";
import DownloadIcon from "@mui/icons-material/Download";
import { Article } from "../models/article";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#EBEBEB",
      contrastText: "#001489",
    },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

interface ArticleCardProps {
  article: Article;
  searchValue: string | null;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article, searchValue }) => {
  // Function to replace searched text with highlighted text
  const highlightText = (text: string) => {
    if (!searchValue) return text; // If no search value, return original text

    const parts = text.split(new RegExp(`(${searchValue})`, "gi")); // Split text by search value, 'gi' means case insensitive and global
    return parts.map((part, index) =>
      part.toLowerCase() === searchValue.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };
  const handleArticleClick = () => {
    const urls = article.printUrls.split(",");
    const articleUrl = urls.find((url) => url.includes("/article"));
    window.open(
      `https://magazinearchive.blob.core.windows.net/resources${articleUrl}`,
      "_blank"
    );
  };
  const handleIssueClick = () => {
    const urls = article.printUrls.split(",");
    const issueUrl = urls.find((url) => url.includes("/issue"));
    window.open(
      `https://magazinearchive.blob.core.windows.net/resources${issueUrl}`,
      "_blank"
    );
  };
  return (
    <Box key={article.identifier}>
      <Paper
        variant="outlined"
        sx={{
          borderColor: "#D3D4D8",
          boxShadow: "0 0 10px 3px rgb(0 0 0 / 0.05)",
        }}
      >
        <div className="flex flex-col sm:flex-row p-2">
          <div className={`sm:shrink-0 ${article.coverImg ? "": "hidden"}`}>
            <Image
              src={article.coverImg.replace(/\.tif$/, ".jpg")}
              className="w-full sm:w-[193px] h-[200px] object-contain sm:object-cover object-top sm:object-center"
            />
            {/* <img
              className="w-full sm:w-[193px] h-[200px] object-cover object-bottom sm:object-center"
              src="https://images.unsplash.com/photo-1637734433731-621aca1c8cb6?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=404&amp;q=80"
              width="202"
              height="192"
              alt="Beautiful abstract building in the sun"
            /> */}
          </div>
          <div className="p-1 2xl:p-1 space-y-2">
            <h2 className="text-md font-bold italic leading-8 tracking-tight  mb-2 text-[#001489]">
              <Link to={`/article/${article.identifier}`}>
                {highlightText(article.title)}
              </Link>
            </h2>
            <Divider color="#C1C5C" />
            <div className="grid md:grid-cols-5 md:grid-rows-1 mt-4 md:space-x-5 grid-rows-2 grid-cols-1">
              <div className="text-xs font-bold tracking-tight hidden md:block">
                <p>
                  Story ID:{" "}
                  <span className="text-[#ADB1B5]"> {article.identifier} </span>
                </p>
                <p>
                  Author:{" "}
                  <span className="text-[#ADB1B5]">{article.creator}</span>
                </p>
                <p>
                  Title:{" "}
                  <span className="text-[#ADB1B5]">
                    {article.issueName} - {article.coverDisplayDate}
                  </span>
                </p>
                <p>
                  Words:{" "}
                  <span className="text-[#ADB1B5]">{article.wordCount}</span>
                </p>
              </div>
              <div className="col-span-3 text-xs ">
                {highlightText(article.teaser)}
              </div>
              <div className="text-xs font-bold tracking-tight md:hidden block">
                <p>
                  Story ID:{" "}
                  <span className="text-[#ADB1B5]"> {article.identifier} </span>
                </p>
                <p>
                  Author:{" "}
                  <span className="text-[#ADB1B5]">{article.creator}</span>
                </p>
                <p>
                  Title:{" "}
                  <span className="text-[#ADB1B5]">
                    {article.issueName} - {article.coverDisplayDate}
                  </span>
                </p>
                <p>
                  Words:{" "}
                  <span className="text-[#ADB1B5]">{article.wordCount}</span>
                </p>
              </div>
              <div className="text-md d-flex">
                <div className="max-w-[150px] ml-auto">
                  <ThemeProvider theme={theme}>
                    <div>
                      <Button
                        sx={{ boxShadow: "none" }}
                        size="small"
                        fullWidth
                        color="neutral"
                        variant="contained"
                        onClick={handleArticleClick}
                        startIcon={<DownloadIcon />}
                      >
                        ARTICLE
                      </Button>
                    </div>
                    <Box sx={{ mt: 1 }}>
                      <Button
                        sx={{ boxShadow: "none" }}
                        size="small"
                        fullWidth
                        color="neutral"
                        variant="contained"
                        onClick={handleIssueClick}
                        startIcon={<DownloadIcon />}
                      >
                        ISSUE
                      </Button>
                    </Box>
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={`grid grid-cols-${article.coverImg ? '6' : '5'} gap-2 p-2`}>
          {article.coverImg && (
            <div className="col-span-1 self-center">
              <div className="w-full md:w-auto md:h-40 overflow-hidden md:col-span-2">
                <Image
                  src={article.coverImg.replace(/\.tif$/, ".jpg")}
                  className="w-full h-full object-cover object-top"
                />
              </div>
            </div>
          )}
          <div className={`col-span-5 my-2`}>
            <h2 className="text-md font-bold italic leading-8 tracking-tight  mb-2 text-[#001489]">
              <Link to={`/article/${article.identifier}`}>
                {highlightText(article.title)}
              </Link>
            </h2>
            <Divider color="#C1C5C" />
            <div className="grid md:grid-cols-5 md:grid-rows-1 mt-4 space-x-5 grid-rows-3 grid-cols-1">
              <div className="text-xs font-bold tracking-tight hidden md:block">
                <p>
                  Story ID:{" "}
                  <span className="text-[#ADB1B5]"> {article.identifier} </span>
                </p>
                <p>
                  Author:{" "}
                  <span className="text-[#ADB1B5]">{article.creator}</span>
                </p>
                <p>
                  Title:{" "}
                  <span className="text-[#ADB1B5]">
                    {article.issueName} - {article.coverDisplayDate}
                  </span>
                </p>
                <p>
                  Words:{" "}
                  <span className="text-[#ADB1B5]">{article.wordCount}</span>
                </p>
              </div>
              <div className="col-span-3 text-xs ">
                {highlightText(article.teaser)}
              </div>
              <div className="text-xs font-bold tracking-tight md:hidden block">
                <p>
                  Story ID:{" "}
                  <span className="text-[#ADB1B5]"> {article.identifier} </span>
                </p>
                <p>
                  Author:{" "}
                  <span className="text-[#ADB1B5]">{article.creator}</span>
                </p>
                <p>
                  Title:{" "}
                  <span className="text-[#ADB1B5]">
                    {article.issueName} - {article.coverDisplayDate}
                  </span>
                </p>
                <p>
                  Words:{" "}
                  <span className="text-[#ADB1B5]">{article.wordCount}</span>
                </p>
              </div>
              <div className="text-md d-flex">
                <div className="max-w-[150px] ml-auto">
                  <ThemeProvider theme={theme}>
                    <div>
                      <Button
                        sx={{ boxShadow: "none" }}
                        size="small"
                        fullWidth
                        color="neutral"
                        variant="contained"
                        onClick={handleArticleClick}
                        startIcon={<DownloadIcon />}
                      >
                        ARTICLE
                      </Button>
                    </div>
                    <Box sx={{ mt: 1 }}>
                      <Button
                        sx={{ boxShadow: "none" }}
                        size="small"
                        fullWidth
                        color="neutral"
                        variant="contained"
                        onClick={handleIssueClick}
                        startIcon={<DownloadIcon />}
                      >
                        ISSUE
                      </Button>
                    </Box>
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Paper>
    </Box>
  );
};

export default ArticleCard;
