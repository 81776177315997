import { Divider } from "@mui/material";
import { FC, useEffect, useState } from "react";

import React from "react";

interface PaginationProps {
  page: number;
  pageSize: number;
  totalItems: number | null;
  q: string | null;
  year: number | null;
  month: number | null;
  author: string | null;
}

const Pagination: FC<PaginationProps> = ({
  page,
  pageSize,
  totalItems,
  q,
  year,
  month,
  author,
}) => {
  const [items, setItems] = useState<number[]>([]);
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);

  useEffect(() => {
    if (!totalItems) return;
    const startItem = (page - 1) * pageSize + 1;
    const endItem = Math.min(startItem + pageSize - 1, totalItems);
    const rangeItems = [];
    const totalPage = Math.ceil(totalItems / pageSize);
    let rangeStartPage = 1;

    if (totalPage >= 5) {
      if (page <= 3) {
        rangeStartPage = 1;
      } else if (page > totalPage - 2) {
        rangeStartPage = totalPage - 4;
      } else {
        rangeStartPage = page - 2;
      }
    } else {
      rangeStartPage = 1;
    }

    for (let i = 0; i < 5; i++) {
      const rangePage = Math.max(1, rangeStartPage + i);
      if (rangePage <= totalPage) {
        rangeItems.push((rangePage - 1) * pageSize + 1);
      }
    }
    setStart(startItem);
    setEnd(endItem);
    setItems([...rangeItems]);
  }, [totalItems]);

  const getBuildParams = (page: number, pageSize: number): string => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    params.set("pageSize", pageSize.toString());
    if (author) params.set("author", author);
    if (month) params.set("month", month.toString());
    if (year) params.set("year", year.toString());
    if (q) params.set("q", q.toString());

    return params.toString();
  };
  if (!totalItems) return <></>;

  return (
    <div className="flex flex-col sm:flex-row justify-between p-3 text-[#ADB1B5] font-normal ">
      <div className="inline-flex justify-start space-x-4">
        <div>
          <p className="font-normal ">
            <span className="text-[#001489]">
              {start}-{end}
            </span>{" "}
            of {totalItems}
          </p>
        </div>
        <div className="inline-flex">
          <p className="mr-2">Items per page:</p>
          {[5, 10, 15].map((value) => (
            <React.Fragment key={value}>
              <a
                href={`?${getBuildParams(1, value)}`}
                className={`${
                  pageSize === value ? "text-[#001489]" : "text-[#ADB1B5]"
                } mx-2`}
              >
                {value}
              </a>
              {value !== 15 && <Divider orientation="vertical" />}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="inline-flex">
        <div className="inline-flex">
          <a href={`?${getBuildParams(1, pageSize)}`}>First</a>
          {items.map((value, index) => (
            <React.Fragment key={value}>
              <a
                href={`?${getBuildParams(
                  Math.floor(value / pageSize) + 1,
                  pageSize
                )}`}
                // href={`?page=${
                //   Math.floor(value / pageSize) + 1
                // }&pageSize=${pageSize}&q=${q}&year=${year}&month=${month}&author=${author}`}
                className={`${
                  page === Math.floor(value / pageSize) + 1
                    ? "text-[#001489]"
                    : "text-[#ADB1B5]"
                } mx-2`}
              >
                {Math.floor(value / pageSize) * pageSize + 1}
              </a>
              {index !== items.length - 1 && <Divider orientation="vertical" />}
            </React.Fragment>
          ))}
          &gt;
          <a
            href={`?${getBuildParams(
              Math.ceil(totalItems / pageSize),
              pageSize
            )}`}
            // href={`?page=${Math.ceil(
            //   totalItems / pageSize
            // )}&pageSize=${pageSize}&q=${q}&year=${year}&month=${month}&author=${author}`}
            className="mx-2"
          >
            Last
          </a>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
