import { Configuration, LogLevel, PopupRequest } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        // signUpSignIn: "B2C_1_demo",
        signIn: "B2C_1_signIn"
        // forgotPassword: "b2c_1_reset",
        // editProfile: "b2c_1_edit_profile"
    },
    authorities: {
        signIn: {
            authority: "https://USFSDataCleanse.b2clogin.com/USFSDataCleanse.onmicrosoft.com/B2C_1_signIn",
        }
    },
    authorityDomain: "USFSDataCleanse.b2clogin.com"
}

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: "56edd90e-75e6-4f88-8adc-92a4c9a7f865", // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signIn.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level:any, message:any, containsPii:any) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }	
            }	
        }	
    }
};


/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
  scopes: ["openid", "profile"],
  loginHint: "example@domain.net"
};

// Scopes you add here will be prompted for consent during login
export const loginRequest: PopupRequest = {
    scopes: [
        'openid', 
        'offline_access', 
        "https://USFSDataCleanse.onmicrosoft.com/api/read"
        // "999fc70e-bf14-4920-a88f-4592144485bc"
    ],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};
