import { Footer } from "flowbite-react"

// implement footer component
const MagazineFooter = () => {
    return (

        <Footer className=" bg-slate-300">
            <div className="container mx-auto my-3 text-center">
                {/* <p>Terms of Use  |   © 2013-21, U.S. Figure Skating, All Rights Reserved. Powered by Quad//Graphics, Inc.  |  Contact Us</p> */}
                <ul className="flex  items-center justify-center mt-3 text-xs font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                    <li>
                        <a href="#" className="mx-1 hover:underline ">Terms of Use</a>
                    </li>
                    <li>
                        <p>|</p>
                    </li>
                    <li>
                        <p className="mx-1"> © 2023 U.S. Figure Skating, All Rights Reserved.</p>
                    </li>
                    <li>
                        <p>|</p>
                    </li>
                    <li>
                        <a href="#" className="mx-1 hover:underline">Contact Us</a>
                    </li>
                </ul>
            </div>
            {/* <Footer.LinkGroup>
                <Footer.Link href="#">
                    About
                </Footer.Link>
                <Footer.Link href="#">
                    Privacy Policy
                </Footer.Link>
                <Footer.Link href="#">
                    Licensing
                </Footer.Link>
                <Footer.Link href="#">
                    Contact
                </Footer.Link>
            </Footer.LinkGroup> */}
        </Footer >
    )
}

export default MagazineFooter